var pre = '/adCenter/';
export default {
  path: '/adCenter',
  title: '素材管理',
  header: 'adCenter',
  custom: 'i-icon-demo i-icon-demo-form',
  children: [{
    path: "".concat(pre, "materialManager"),
    title: '素材管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "materialManager/picture"),
      title: '图片',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "materialManager/video"),
      title: '视频',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "materialManager/file"),
      title: '文件',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "materialManager/music"),
      title: '音乐',
      auth: ['superadmin']
    }, {
      path: "".concat(pre, "materialManager/audio"),
      title: '音频'
    }]
  }, {
    path: "".concat(pre, "pictureSettings"),
    title: '图片设置',
    auth: ['superadmin'],
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "pictureSettings/watermark"),
      title: '水印图片'
    }, {
      path: "".concat(pre, "pictureSettings/label"),
      title: '图片标签'
    }]
  }]
};