var _layout;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * iView Admin Pro 业务配置
 * */
var env = process.env.NODE_ENV;
var Setting = {
  /**
   * 基础配置
   * */
  // 网页标题的后缀
  titleSuffix: '国贸基地联播管理平台',
  // 路由模式，可选值为 history 或 hash
  routerMode: 'hash',
  // 应用的基路径
  routerBase: '/',
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
  // 接口请求地址
  // 生产
  // apiBaseURL: env === 'development' ? 'https://agri.china-plus.net/api/' : 'https://agri.china-plus.net/api/',
  // 测试
  apiBaseURL: env === 'development' ? 'https://agri.china-plus.net/api/' : 'https://agri.china-plus.com.cn/api/',
  // 生产H5外链
  H5Path: env === 'development' ? 'http://agritest.china-plus.net/new' : 'https://agri.china-plus.com.cn/new',
  // 测试H5外链
  // H5Path: env === 'development' ? 'https://agritest.china-plus.net/new' : 'https://agritest.china-plus.net/new',
  // 接口请求返回错误时，弹窗的持续时间，单位：秒
  modalDuration: 3,
  // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
  errorModalType: 'Message',
  // Cookies 默认保存时间，单位：天
  // cookiesExpires:0.0001, //0.0001 = 8.64 秒
  cookiesExpires: 1,
  //阿里云转码模板id（音频） 8a10a7ded5394502dd939a81db1a54e3
  templateGroupId: '32432f2ae3abbacabcea7f697034fe56',

  /**
  * 多语言配置
  * */
  i18n: {
    // 默认语言
    default: 'zh-CN',
    // 是否根据用户电脑配置自动设置语言（仅第一次有效）
    auto: false
  },

  /**
   * 布局配置
   * */
  // 侧边菜单宽度，单位 px，不可动态修改，需与 setting.less 的 @menuSideWidth 保持一致
  menuSideWidth: 200,
  layout: (_layout = {
    // 侧边栏风格，可选值为 dark 或 light
    siderTheme: 'light',
    // 顶栏风格，可选值为 light、dark 或 primary
    headerTheme: 'primary',
    // 顶栏是否置顶，开启后会覆盖侧边栏，需开启 headerFix
    headerStick: true,
    headerFix: true,
    // 是否开启多 Tabs 页签
    tabs: false,
    // 多 Tabs 页签是否显示图标，开启 tabs 时有效
    showTabsIcon: true,
    // 是否固定多 Tabs 多页签
    tabsFix: true,
    // 再次点击 Tabs 页签时，是否重载当前页面
    tabsReload: false,
    // 页签是否支持拖拽排序
    tabsOrder: true,
    // 是否固定侧边栏
    siderFix: true
  }, _defineProperty(_layout, "headerFix", true), _defineProperty(_layout, "headerHide", false), _defineProperty(_layout, "headerMenu", false), _defineProperty(_layout, "menuAccordion", true), _defineProperty(_layout, "showSiderCollapse", true), _defineProperty(_layout, "menuCollapse", false), _defineProperty(_layout, "menuSiderReload", false), _defineProperty(_layout, "menuHeaderReload", false), _defineProperty(_layout, "showCollapseMenuTitle", false), _defineProperty(_layout, "showReload", true), _defineProperty(_layout, "showSearch", true), _defineProperty(_layout, "showNotice", true), _defineProperty(_layout, "showFullscreen", true), _defineProperty(_layout, "showMobileLogo", true), _defineProperty(_layout, "showBreadcrumb", true), _defineProperty(_layout, "showBreadcrumbIcon", false), _defineProperty(_layout, "showLog", false), _defineProperty(_layout, "showI18n", true), _defineProperty(_layout, "enableSetting", true), _defineProperty(_layout, "logoutConfirm", true), _layout),

  /**
   * 多页 Tabs
   * */
  page: {
    // 默认打开的页签
    opened: []
  },

  /**
   * 功能配置
   * */
  // 相同路由，不同参数间进行切换，是否强力更新
  sameRouteForceUpdate: false,
  // 是否使用动态侧边菜单
  dynamicSiderMenu: true
};
export default Setting;