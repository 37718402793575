var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "i-layout-header-trigger i-layout-header-trigger-min" },
    [
      _c(
        "Dropdown",
        {
          staticClass: "i-layout-header-user",
          class: { "i-layout-header-user-mobile": _vm.isMobile },
          attrs: { trigger: _vm.isMobile ? "click" : "hover" },
          on: { "on-click": _vm.handleClick }
        },
        [
          _vm.info.avatar
            ? _c("Avatar", { attrs: { size: "small", src: _vm.info.avatar } })
            : _vm._e(),
          !_vm.isMobile
            ? _c("span", { staticClass: "i-layout-header-user-name" }, [
                _vm._v(_vm._s(_vm.info.name))
              ])
            : _vm._e(),
          _c(
            "DropdownMenu",
            { attrs: { slot: "list" }, slot: "list" },
            [
              _c(
                "i-link",
                { attrs: { to: "/setting/user" } },
                [
                  _c(
                    "DropdownItem",
                    [
                      _c("Icon", { attrs: { type: "ios-contact-outline" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("basicLayout.user.center")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "i-link",
                [
                  _c(
                    "DropdownItem",
                    [
                      _c("Icon", { attrs: { type: "ios-settings-outline" } }),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.passwordShow = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("basicLayout.user.setting")))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "DropdownItem",
                { attrs: { divided: "", name: "logout" } },
                [
                  _c("Icon", { attrs: { type: "ios-log-out" } }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicLayout.user.logOut")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: "修改密码", width: "450", "footer-hide": "" },
          model: {
            value: _vm.passwordShow,
            callback: function($$v) {
              _vm.passwordShow = $$v
            },
            expression: "passwordShow"
          }
        },
        [
          _c(
            "div",
            { staticClass: "banned" },
            [
              _c(
                "Form",
                {
                  ref: "passwordUp",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.passwordUp,
                    "label-position": "right",
                    "label-width": 100
                  }
                },
                [
                  _c(
                    "Form-item",
                    { attrs: { label: "原密码", prop: "oldPassword" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "270px" },
                        attrs: {
                          type: "text",
                          placeholder: "请输入原密码",
                          clearable: ""
                        },
                        model: {
                          value: _vm.passwordUp.oldPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.passwordUp, "oldPassword", $$v)
                          },
                          expression: "passwordUp.oldPassword"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "Form-item",
                    { attrs: { label: "新密码", prop: "newPassword" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "270px" },
                        attrs: {
                          type: "text",
                          placeholder: "请输入新密码",
                          clearable: ""
                        },
                        model: {
                          value: _vm.passwordUp.newPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.passwordUp, "newPassword", $$v)
                          },
                          expression: "passwordUp.newPassword"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "Form-item",
                    { attrs: { label: "再次输入密码" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "270px" },
                        attrs: {
                          type: "text",
                          placeholder: "请再次输入密码",
                          clearable: ""
                        },
                        model: {
                          value: _vm.toPassword,
                          callback: function($$v) {
                            _vm.toPassword = $$v
                          },
                          expression: "toPassword"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "banned-add" },
                [
                  _c(
                    "Button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { shape: "circle" },
                      on: {
                        click: function($event) {
                          _vm.passwordShow = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "Button",
                    {
                      staticClass: "ivu-ml-8",
                      staticStyle: { width: "100px" },
                      attrs: { type: "primary", shape: "circle" },
                      on: {
                        click: function($event) {
                          return _vm.psSubmit()
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }