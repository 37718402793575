function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'userCenter-';
export default {
  path: '/userCenter',
  name: 'userCenter',
  redirect: {
    name: "".concat(pre, "organizational-manage/type-manage")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'organizational-manage/type-manage',
    name: "".concat(pre, "type-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '类型管理'
    }),
    component: function component() {
      return import('@/pages/userCenter/organizational-manage/type-manage');
    }
  }, {
    path: 'organizational-manage/configure',
    name: "".concat(pre, "configure"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '机构配置'
    }),
    component: function component() {
      return import('@/pages/userCenter/organizational-manage/configure');
    }
  }, {
    path: 'organizational-manage/role-manage',
    name: "".concat(pre, "role-manage"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '角色&成员管理'
    }),
    component: function component() {
      return import('@/pages/userCenter/organizational-manage/role-manage');
    }
  }, {
    path: 'role-manage/role',
    name: "".concat(pre, "role"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '角色管理'
    }),
    component: function component() {
      return import('@/pages/userCenter/role-manage/role');
    }
  }, {
    path: 'role-manage/user',
    name: "".concat(pre, "user"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '用户管理'
    }),
    component: function component() {
      return import('@/pages/userCenter/role-manage/user');
    }
  }, {
    path: 'data-manage/article',
    name: "".concat(pre, "article"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '文章数据'
    }),
    component: function component() {
      return import('@/pages/userCenter/data-manage/article');
    }
  }, {
    path: 'data-manage/material',
    name: "".concat(pre, "material"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '素材数据'
    }),
    component: function component() {
      return import('@/pages/userCenter/data-manage/material');
    }
  }, {
    path: 'process-manage/overallProcess',
    name: "".concat(pre, "overallProcess"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '总流程列表'
    }),
    component: function component() {
      return import('@/pages/userCenter/process-manage/overallProcess');
    }
  }, {
    path: 'process-manage/subprocess',
    name: "".concat(pre, "subprocess"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '子流程列表'
    }),
    component: function component() {
      return import('@/pages/userCenter/process-manage/subprocess');
    }
  }, {
    path: 'process-manage/overallProcess/work-flow-step1',
    name: "".concat(pre, "work-flow-step1"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设置审核总流程'
    }),
    component: function component() {
      return import('@/pages/userCenter/process-manage/workFlowStep1');
    }
  }, {
    path: 'process-manage/overallProcess/work-flow-step2',
    name: "".concat(pre, "work-flow-step2"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设置审核子流程'
    }),
    component: function component() {
      return import('@/pages/userCenter/process-manage/workFlowStep2');
    }
  }, {
    path: 'agribusiness/index',
    name: "".concat(pre, "index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '农企列表'
    }),
    component: function component() {
      return import('@/pages/userCenter/agribusiness/index');
    }
  }, {
    path: '/list-detailed',
    name: "".concat(pre, "list-detailed"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '详细'
    }),
    component: function component() {
      return import('@/pages/userCenter/agribusiness/list-detailed');
    }
  }, {
    path: 'organizational-manage/institutions',
    name: "".concat(pre, "institutions"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '农业机构列表'
    }),
    component: function component() {
      return import('@/pages/userCenter/organizational-manage/institutions');
    }
  }, {
    path: 'cooperative-media/index',
    name: "".concat(pre, "index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '合作媒体中心'
    }),
    component: function component() {
      return import('@/pages/userCenter/cooperative-media/index');
    }
  }, {
    path: '/index-detailed',
    name: "".concat(pre, "index-detailed"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '详细'
    }),
    component: function component() {
      return import('@/pages/userCenter/cooperative-media/index-detailed');
    }
  }]
};