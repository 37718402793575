var pre = '/userCenter/';
export default {
  path: '/userCenter',
  title: '组织机构管理',
  header: 'userCenter',
  custom: 'ivu-icon ivu-icon-md-people',
  children: [{
    path: "".concat(pre, "organizational-manage"),
    title: '机构管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "organizational-manage/type-manage"),
      title: '类型管理'
    }, {
      path: "".concat(pre, "organizational-manage/configure"),
      title: '机构配置'
    }, {
      path: "".concat(pre, "organizational-manage/role-manage"),
      title: '角色&成员管理'
    }, {
      path: "".concat(pre, "organizational-manage/institutions"),
      title: '农业机构列表'
    }]
  }, {
    path: "".concat(pre, "process-manage"),
    title: '流程管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "process-manage/process"),
      title: '流程管理'
    } // {
    //     path: `${pre}role-manage/user`,
    //     title: '用户管理'
    // },
    ]
  }, {
    path: "".concat(pre, "data-manage"),
    title: '数据管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "data-manage/article"),
      title: '文章数据'
    }, {
      path: "".concat(pre, "data-manage/material"),
      title: '素材数据'
    }]
  }, {
    path: "".concat(pre, "agribusiness"),
    title: '农业管理中心',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "agribusiness/index"),
      title: '农企列表'
    }, {
      path: "".concat(pre, "agribusiness/list-detailed"),
      title: '详细'
    }]
  }, {
    path: "".concat(pre, "cooperative-media"),
    title: '合作媒体中心',
    children: [{
      path: "".concat(pre, "cooperative-media/index"),
      title: '媒体列表'
    }, {
      path: "".concat(pre, "cooperative-media/index-detailed"),
      title: '详细'
    }]
  }]
};