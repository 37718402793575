import axios from "axios";
var access_token = '';
var uid = [];

function wxId() {
  return axios({
    url: 'https://agritest.china-plus.net/wx/cgi-bin/token',
    method: 'get',
    params: {
      grant_type: 'client_credential',
      appid: 'wx4edb370b9f50fd38',
      secret: 'eae2b3f47007bdd271ff3dee9eec7ebd'
    }
  }).then(function (res) {
    console.log('微信', res.data);
    access_token = res.data.access_token;
    return unserInof().then(function () {
      return {
        access_token: access_token,
        uid: uid
      };
    });
  });
}

function unserInof() {
  return axios({
    url: 'https://agritest.china-plus.net/wx/cgi-bin/user/get',
    method: 'get',
    params: {
      access_token: access_token,
      next_openid: ''
    }
  }).then(function (res) {
    console.log('微信用户信息', res.data);
    var userPromises = res.data.data.openid.map(function (itemUid) {
      return userUnionID(itemUid);
    });
    return Promise.all(userPromises).then(function () {
      return uid;
    });
  });
}

function userUnionID(itemUid) {
  return axios({
    url: 'https://agritest.china-plus.net/wx/cgi-bin/user/info',
    method: 'get',
    params: {
      access_token: access_token,
      openid: itemUid
    }
  }).then(function (res) {
    uid.push(res.data);
  });
}

export default wxId;