// 菜单，顶栏
export default [{
  path: '/',
  title: '首页',
  icon: 'md-home',
  hideSider: false,
  // 是否隐藏侧边栏
  name: 'home',
  // 用 name 区分哪些二级菜单显示
  auth: ['op', 'admin'] // 权限

}, {
  path: '/adCenter',
  title: '素材管理',
  icon: 'md-home',
  hideSider: false,
  // 是否隐藏侧边栏
  name: 'adCenter',
  // 用 name 区分哪些二级菜单显示
  auth: ['op', 'admin'] // 权限

}, // {
//     path: '/list',
//     title: '列表页面',
//     icon: 'md-home',
//     hideSider: false, // 是否隐藏侧边栏
//     name: 'list', // 用 name 区分哪些二级菜单显示
//     auth: ['op', 'admin'] // 权限
// },
// {
//     path: '/form',
//     title: '表单页面',
//     icon: 'md-home',
//     hideSider: false, // 是否隐藏侧边栏
//     name: 'form', // 用 name 区分哪些二级菜单显示
//     auth: ['op', 'admin'] // 权限
// },
// {
//     path: '/tool',
//     title: '工具',
//     icon: 'md-locate',
//     hideSider: false,
//     name: 'tool',
//     auth: ['op', 'admin']
// },
{
  path: '/newHome',
  title: '首页',
  icon: 'md-home',
  hideSider: true,
  name: 'newHome',
  auth: ['op', 'admin']
}, {
  path: '/contentCenter',
  title: '内容中心',
  icon: 'md-home',
  hideSider: false,
  name: 'contentCenter',
  auth: ['op', 'admin']
}, {
  path: '/userManage',
  title: '会员中心',
  icon: 'md-home',
  hideSider: false,
  name: 'userManage',
  auth: ['op', 'admin']
}, {
  path: '/appCenter',
  title: '应用中心',
  icon: 'md-home',
  hideSider: false,
  name: 'appCenter',
  auth: ['op', 'admin']
}, {
  path: '/syntheticalCenter',
  title: '综合中心',
  icon: 'md-home',
  hideSider: false,
  name: 'syntheticalCenter',
  auth: ['op', 'admin']
}, {
  path: '/addPlate',
  title: 'app编辑',
  icon: 'md-home',
  hideSider: false,
  name: 'addPlate',
  auth: ['op', 'admin']
}, {
  path: '/appCenter/global/Basics/edit',
  title: '新增/编辑',
  icon: 'md-home',
  hideSider: false,
  name: 'BasicsEdit',
  auth: ['op', 'admin']
}, {
  path: '/editArticle',
  title: '编辑文章',
  icon: 'md-home',
  hideSider: true,
  name: 'editArticle',
  auth: ['op', 'admin']
}, {
  path: '/edit-institutionalArticles',
  title: '编辑机构文章',
  icon: 'md-home',
  hideSider: true,
  name: 'edit-institutionalArticles',
  auth: ['op', 'admin']
}, {
  path: '/syntheticalCenter/statistics/data-analysis',
  title: '内容分析',
  icon: 'md-home',
  hideSider: true,
  name: 'data-analysis',
  auth: ['op', 'admin']
}, {
  path: '/appCenter/data-analysis',
  title: '用户端-内容分析',
  icon: 'md-home',
  hideSider: true,
  name: 'data-analysis',
  auth: ['op', 'admin']
}, {
  path: '/editIconComponents',
  title: '编辑组件',
  icon: 'md-home',
  hideSider: true,
  name: 'editIconComponents',
  auth: ['op', 'admin']
}, {
  path: '/userCenter/process-manage/overallProcess/work-flow-step1',
  title: '设置审核总流程',
  icon: 'md-home',
  hideSider: false,
  name: 'work-flow-step1'
}, {
  path: '/userCenter/process-manage/overallProcess/work-flow-step2',
  title: '设置审核子流程',
  icon: 'md-home',
  hideSider: false,
  name: 'work-flow-step2'
}, {
  path: '/edit',
  title: '编辑问卷',
  icon: 'md-home',
  hideSider: false,
  name: 'edit-questionnaire'
}, {
  path: '/marketingManager/content-promotion/editorial-promotion',
  title: '编辑推广内容',
  icon: 'md-home',
  hideSider: false,
  name: 'editorial-promotion'
}];