function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from "vuex";
import { changePasswords } from "@api/system";
export default {
  name: "iHeaderUser",
  computed: _objectSpread(_objectSpread({}, mapState("admin/user", ["info"])), mapState("admin/layout", ["isMobile", "logoutConfirm"])),
  data: function data() {
    return {
      passwordShow: false,
      passwordUp: {
        oldPassword: "",
        newPassword: "",
        id: this.$store.state.admin.user.info.uid
      },
      toPassword: '',
      rules: {
        oldPassword: [{
          required: true,
          message: "请输入原密码",
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "请输入新密码",
          trigger: "blur"
        }],
        toPassword: [{
          required: true,
          message: "请再次输入密码",
          trigger: "blur"
        }]
      }
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions("admin/account", ["logout"])), {}, {
    handleClick: function handleClick(name) {
      if (name === "logout") {
        this.logout({
          confirm: this.logoutConfirm,
          vm: this
        });
      }
    },
    psSubmit: function psSubmit() {
      var _this = this;

      this.$refs.passwordUp.validate(function (valid) {
        if (valid) {
          if (_this.passwordUp.newPassword !== _this.toPassword) {
            _this.$Notice.warning({
              title: "提示",
              desc: "两次密码不一致"
            });

            return false;
          }

          changePasswords(_this.passwordUp).then(function (res) {
            _this.$Notice.success({
              title: "提示",
              desc: "修改成功"
            });

            _this.passwordShow = false;

            _this.$refs.passwordUp.resetFields();
          });
        }
      });
    }
  })
};