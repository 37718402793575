var pre = '/contentCenter/';
var SUPERADMIN = [];
export default {
  path: '/contentCenter',
  title: '内容中心',
  header: 'contentCenter',
  custom: 'i-icon-demo i-icon-demo-form',
  children: [{
    path: "".concat(pre, "releaseManager"),
    title: '内容发布管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "releaseManager/article"),
      title: '文章发布'
    }, {
      path: "".concat(pre, "releaseManager/ad"),
      title: 'Banner发布',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, // {
    //     path: `${pre}releaseManager/columnAd`,
    //     title: '通栏广告发布'
    // },
    {
      path: "".concat(pre, "releaseManager/translate"),
      title: '翻译发布',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "releaseManager/navigationMap"),
      title: '导航图发布',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "releaseManager/openScreen"),
      title: '开屏图发布',
      auth: ['admin', 'zhubian', 'xiaobian3', 'xiaobian4']
    }, {
      path: "".concat(pre, "releaseManager/push"),
      title: 'PUSH推送发布',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "releaseManager/newsRelease"),
      title: '消息发布',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "releaseManager/popup"),
      title: '弹窗发布',
      auth: ['admin', 'zhubian', 'xiaobian4']
    }, {
      path: "".concat(pre, "releaseManager/institutional-articles"),
      title: '机构文章发布',
      children: [{
        path: "".concat(pre, "releaseManager/overseas-promotion"),
        title: '海外推广案例'
      }, {
        path: "".concat(pre, "releaseManager/merchant-marketing"),
        title: '商家营销案例'
      }, {
        path: "".concat(pre, "releaseManager/media-details"),
        title: '媒体详情页案例'
      }]
    }]
  }, {
    path: "".concat(pre, "verifyManager"),
    title: '内容审核管理',
    children: [{
      path: "".concat(pre, "verifyManager/article"),
      title: '文章审核'
    }]
  }, {
    path: "".concat(pre, "sortManager"),
    title: '排序管理',
    auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian4'],
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "sortManager/article"),
      title: '文章排序',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "sortManager/special"),
      title: '专题排序',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "sortManager/compartmentSmall"),
      title: '分栏组件排序',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "sortManager/multiColumn"),
      title: '多栏目组件排序',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "sortManager/carousel"),
      title: '轮播位排序',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "sortManager/ad"),
      title: 'Banner排序',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian2', 'xiaobian4']
    }, {
      path: "".concat(pre, "sortManager/translate"),
      title: '翻译词组排序',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "sortManager/articleCase"),
      title: '案例文章排序',
      auth: ['admin', 'xiaobian4']
    }]
  }, {
    path: "".concat(pre, "contentConfiguration"),
    title: '内容配置',
    auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian3', 'xiaobian4'],
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "contentConfiguration/multipleColumns"),
      title: '视听配置',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "contentConfiguration/special"),
      title: '专题配置',
      auth: ['admin', 'zhubian', 'xiaobian', 'xiaobian3', 'xiaobian4']
    }, {
      path: "".concat(pre, "contentConfiguration/source"),
      title: '来源配置',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "contentConfiguration/sensitiveWords"),
      title: '敏感词配置',
      auth: ['admin', 'xiaobian4']
    }, {
      path: "".concat(pre, "contentConfiguration/translate"),
      title: '翻译词组配置',
      auth: ['admin', 'xiaobian4']
    }]
  }]
};