function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'contentCenter-';
export default {
  path: '/contentCenter',
  name: 'contentCenter',
  redirect: {
    name: "".concat(pre, "materialManager/picture")
  },
  meta: meta,
  component: BasicLayout,
  children: [// ============内容发布管理=================
  {
    path: 'releaseManager/article',
    name: "".concat(pre, "releaseManager-article"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '文章发布' // perms:['add','search']

    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/article');
    }
  }, {
    path: 'releaseManager/institutional-articles',
    name: "".concat(pre, "institutional-article"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '机构文章发布' // perms:['add','search']

    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/institutionalArticles');
    }
  }, {
    path: 'releaseManager/overseas-promotion',
    name: "".concat(pre, "overseas-promotion"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '海外推广案例' // perms:['add','search']

    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/overseas-promotion');
    }
  }, {
    path: 'releaseManager/merchant-marketing',
    name: "".concat(pre, "overseas-promotion"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '商家营销案例' // perms:['add','search']

    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/overseas-promotion');
    }
  }, {
    path: 'releaseManager/media-details',
    name: "".concat(pre, "overseas-promotion"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '媒体详情页案例' // perms:['add','search']

    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/overseas-promotion');
    }
  }, // {
  //     path: 'releaseManager/article/add',
  //     name: `${pre}编辑文章`,
  //     meta: {
  //         ...meta,
  //         title: '编辑文章'
  //     },
  //     component: () => import('@/pages/contentCenter/releaseManager/article/add')
  // },
  {
    path: 'releaseManager/ad',
    name: "".concat(pre, "releaseManager-ad"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'Banner发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/ad');
    }
  }, {
    path: 'releaseManager/columnAd',
    name: "".concat(pre, "\u901A\u680F\u5E7F\u544A\u53D1\u5E03"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '通栏广告发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/columnAd');
    }
  }, {
    path: 'releaseManager/translate',
    name: "".concat(pre, "\u7FFB\u8BD1\u53D1\u5E03"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '翻译发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/translate');
    }
  }, {
    path: 'releaseManager/navigationMap',
    name: "".concat(pre, "\u5BFC\u822A\u56FE\u53D1\u5E03"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '导航图发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/navigationMap');
    }
  }, {
    path: 'releaseManager/openScreen',
    name: "".concat(pre, "\u5F00\u5C4F\u56FE\u53D1\u5E03"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '开屏图发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/openScreen');
    }
  }, {
    path: 'releaseManager/push',
    name: "".concat(pre, "PUSH\u63A8\u9001\u53D1\u5E03"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'PUSH推送发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/push');
    }
  }, {
    path: 'releaseManager/newsRelease',
    name: "".concat(pre, "\u6D88\u606F\u53D1\u5E03"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '消息发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/newsRelease');
    }
  }, {
    path: 'releaseManager/popup',
    name: "".concat(pre, "\u5F39\u7A97\u53D1\u5E03"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '弹窗发布'
    }),
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/popup');
    }
  }, //=========内容审核管理============
  {
    path: 'verifyManager/article',
    name: "".concat(pre, "verifyManager-article"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '文章审核'
    }),
    component: function component() {
      return import('@/pages/contentCenter/verifyManager/article');
    }
  }, // ============排序管理=================
  {
    path: 'sortManager/article',
    name: "".concat(pre, "\u6587\u7AE0\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '文章排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/article');
    }
  }, {
    path: 'sortManager/special',
    name: "".concat(pre, "\u4E13\u9898\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '专题排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/special');
    }
  }, {
    path: 'sortManager/compartmentSmall',
    name: "".concat(pre, "\u5206\u680F\u7EC4\u4EF6\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '分栏组件排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/compartmentSmall');
    }
  }, {
    path: 'sortManager/multiColumn',
    name: "".concat(pre, "\u591A\u680F\u76EE\u7EC4\u4EF6\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '多栏目组件排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/multiColumn');
    }
  }, {
    path: 'sortManager/media',
    name: "".concat(pre, "\u5A92\u4F53\u5B9A\u5236\u9875\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '媒体定制页排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/media');
    }
  }, {
    path: 'sortManager/organ',
    name: "".concat(pre, "\u673A\u6784\u5217\u8868\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '机构列表排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/organ');
    }
  }, {
    path: 'sortManager/carousel',
    name: "".concat(pre, "\u8F6E\u64AD\u4F4D\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '轮播位排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/carousel');
    }
  }, {
    path: 'sortManager/ad',
    name: "".concat(pre, "Banner\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'Banner排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/ad');
    }
  }, {
    path: 'sortManager/translate',
    name: "".concat(pre, "\u7FFB\u8BD1\u8BCD\u7EC4\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '翻译词组排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/translate');
    }
  }, // ============内容配置=================
  {
    path: 'contentConfiguration/Audiovisual',
    name: "".concat(pre, "\u89C6\u542C\u914D\u7F6E"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '视听配置'
    }),
    component: function component() {
      return import('@/pages/contentCenter/contentConfiguration/Audiovisual');
    }
  }, {
    path: 'contentConfiguration/special',
    name: "".concat(pre, "\u4E13\u9898\u914D\u7F6E"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '专题配置'
    }),
    component: function component() {
      return import('@/pages/contentCenter/contentConfiguration/special');
    }
  }, {
    path: 'contentConfiguration/source',
    name: "".concat(pre, "\u6765\u6E90\u914D\u7F6E"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '来源配置'
    }),
    component: function component() {
      return import('@/pages/contentCenter/contentConfiguration/source');
    }
  }, {
    path: 'contentConfiguration/sensitiveWords',
    name: "".concat(pre, "\u654F\u611F\u8BCD\u914D\u7F6E"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '敏感词配置'
    }),
    component: function component() {
      return import('@/pages/contentCenter/contentConfiguration/sensitiveWords');
    }
  }, {
    path: 'contentConfiguration/translate',
    name: "".concat(pre, "\u7FFB\u8BD1\u8BCD\u7EC4\u914D\u7F6E"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '翻译词组配置'
    }),
    component: function component() {
      return import('@/pages/contentCenter/contentConfiguration/translate');
    }
  }, // 文章案例排序
  {
    path: 'sortManager/articleCase',
    name: "".concat(pre, "\u6848\u4F8B\u6587\u7AE0\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '案例文章排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/articleCase');
    }
  }, {
    path: 'sortManager/mediaDetails',
    name: "".concat(pre, "\u5A92\u4F53\u8BE6\u60C5\u9875\u6392\u5E8F"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '媒体详情页排序'
    }),
    component: function component() {
      return import('@/pages/contentCenter/sortManager/mediaDetails');
    }
  }]
};