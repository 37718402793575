var pre = '/messageCenter/';
export default {
  path: '/messageCenter',
  title: '消息中心',
  header: 'index',
  custom: 'i-icon-demo i-icon-demo-list',
  children: [{
    path: "".concat(pre, "/notification"),
    title: '消息通知'
  }]
};