import dashboard from './modules/dashboard';
import form from './modules/form';
import list from './modules/list';
import search from './modules/search';
import profile from './modules/profile';
import result from './modules/result';
import exception from './modules/exception';
import setting from './modules/setting';
import editor from './modules/editor';
import tool from './modules/tool';
import adCenter from './modules/adCenter';
import appCenter from './modules/appCenter';
import contentCenter from './modules/contentCenter';
import userManage from './modules/userManage';
import syntheticalCenter from './modules/syntheticalCenter';
import menuManage from './modules/menuManage';
import questionnaire from './modules/questionnaire';
import contactUs from './modules/contactUs';
import registrationList from './modules/registrationList';
import marketingManager from './modules/marketingManager';
import BasicLayout from '@/layouts/basic-layout';
import messageCenter from './modules/messageCenter';
/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  redirect: {
    name: 'newHome' //重定向

  },
  component: BasicLayout,
  children: [{
    path: 'index',
    name: 'index',
    redirect: {
      name: 'newHome'
    }
  }, {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: function component() {
      return import('@/pages/system/log');
    }
  }, {
    path: 'newHome',
    name: 'newHome',
    meta: {
      title: '首页',
      auth: true,
      closable: false
    },
    component: function component() {
      return import('@/pages/newHome/index');
    }
  }, // {
  //     path: '/addPlate',
  //     name: 'addPlate',
  //     meta: {
  //         title: 'APP编辑',
  //         auth: true,
  //         closable: false
  //     },
  //     component: () => import('@/pages/appCenter/global/templates/addPlate')
  // },
  {
    path: '/editArticle',
    name: 'editArticle',
    meta: {
      title: '编辑文章',
      auth: true,
      cache: true,
      closable: false
    },
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/article/edit');
    }
  }, {
    path: '/edit-institutionalArticles',
    name: 'edit-institutionalArticles',
    meta: {
      title: '编辑机构文章',
      auth: true,
      cache: true,
      closable: false
    },
    component: function component() {
      return import('@/pages/contentCenter/releaseManager/institutionalArticles/edit');
    }
  }, {
    path: '/editIconComponents',
    name: 'editIconComponents',
    meta: {
      title: '组件编辑',
      auth: true,
      closable: false
    },
    component: function component() {
      return import('@/pages/appCenter/global/templates/editIconComponents');
    }
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(from.fullPath);
        });
      },
      render: function render(h) {
        return h();
      }
    }
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(JSON.parse(from.params.route));
        });
      },
      render: function render(h) {
        return h();
      }
    }
  }, {
    path: 'i18n',
    name: 'i18n',
    meta: {
      title: '$t:menu.i18n',
      auth: true
    },
    component: function component() {
      return import('@/pages/i18n');
    }
  }]
}, dashboard, adCenter, appCenter, contentCenter, userManage, syntheticalCenter, //综合中心
menuManage, //数据权限管理
questionnaire, contactUs, // 联系我们
registrationList, //活动报名
marketingManager, messageCenter // form,
// list,
// search,
// profile,
// result,
// exception,
// setting,
// editor,
// tool
];
/**
 * 在主框架之外显示
 */

var frameOut = [{
  path: '/privacyPolicy',
  name: 'privacyPolicy',
  meta: {
    title: '隐私政策',
    auth: false // 不需要登录验证

  },
  component: function component() {
    return import('@/pages/privacyPolicy/index');
  }
}, {
  path: '/videoProtocol',
  name: 'videoProtocol',
  meta: {
    title: '视频上传版权协议',
    auth: false // 不需要登录验证

  },
  component: function component() {
    return import('@/pages/videoProtocol/index');
  }
}, // 登录
{
  path: '/login',
  name: 'login',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/account/login');
  }
}, // 注册
{
  path: '/register',
  name: 'register',
  meta: {
    title: '$t:page.register.title'
  },
  component: function component() {
    return import('@/pages/account/register');
  }
}, // 注册结果
{
  path: '/register/result',
  name: 'register-result',
  meta: {
    auth: true,
    title: '注册结果'
  },
  component: function component() {
    return import('@/pages/account/register/result');
  }
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '/403',
  name: '403',
  meta: {
    title: '403'
  },
  component: function component() {
    return import('@/pages/system/error/403');
  }
}, {
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: function component() {
    return import('@/pages/system/error/500');
  }
}, {
  path: '*',
  name: '404',
  meta: {
    title: '404'
  },
  component: function component() {
    return import('@/pages/system/error/404');
  }
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default [].concat(frameIn, frameOut, errorPage);