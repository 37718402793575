import request from '@/plugins/request';
import qs from 'qs';
var appMark = localStorage.getItem('appmark'); // export function GetUserList (data) {
//     return request({
//         url: '/api/system/user/list',
//         method: 'post',
//         data
//     });
// }
//获取阿里云密钥

export function getAccessKeyId(params) {
  return request({
    url: '/omnimedia-app-universal/aliyun/getAccessKeyId',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //获取OSS 临时token

export function getOsstokens(params) {
  return request({
    url: '/omnimedia-app-universal/aliyun/getOsstokens',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}
export function GetUserList(params) {
  // params = qs.stringify(params)
  return request({
    url: '/omnimedia-web-usercenter/user/queryUserList',
    method: 'get',
    params: params,
    headers: {
      'appMark': 'master.app'
    }
  });
}
export function DeleteUser(data) {
  return request({
    url: '/api/system/user/delete',
    method: 'post',
    data: data
  });
}
export function DeleteUserMultiple(data) {
  return request({
    url: '/api/system/user/delete/multiple',
    method: 'post',
    data: data
  });
}
export function UpdateUser(data) {
  return request({
    url: '/api/system/user/update',
    method: 'post',
    data: data
  });
}
export function CreateUser(data) {
  return request({
    url: '/api/system/user/create',
    method: 'post',
    data: data
  });
}
export function GetRoleList(data) {
  return request({
    url: '/api/system/role/list',
    method: 'post',
    data: data
  });
}
export function DeleteRole(data) {
  return request({
    url: '/api/system/role/delete',
    method: 'post',
    data: data
  });
}
export function DeleteRoleMultiple(data) {
  return request({
    url: '/api/system/role/delete/multiple',
    method: 'post',
    data: data
  });
}
export function UpdateRole(data) {
  return request({
    url: '/api/system/role/update',
    method: 'post',
    data: data
  });
}
export function CreateRole(data) {
  return request({
    url: '/api/system/role/create',
    method: 'post',
    data: data
  });
}
export function GetMenuList(data) {
  return request({
    url: '/api/system/menu/list',
    method: 'post',
    data: data
  });
}
export function UpdateRoleMenu(data) {
  return request({
    url: '/api/system/role/menu/update',
    method: 'post',
    data: data
  });
}
export function CreateHeaderMenu(data) {
  return request({
    url: '/api/system/menu/header/create',
    method: 'post',
    data: data
  });
}
export function CreateSiderMenu(data) {
  return request({
    url: '/api/system/menu/sider/create',
    method: 'post',
    data: data
  });
}
export function DeleteMenu(data) {
  return request({
    url: '/api/system/menu/delete',
    method: 'post',
    data: data
  });
}
export function DeleteMenuMultiple(data) {
  return request({
    url: '/api/system/menu/delete/multiple',
    method: 'post',
    data: data
  });
}
export function UpdateMenu(data) {
  return request({
    url: '/api/system/menu/update',
    method: 'post',
    data: data
  });
} //获取所有菜单权限列表

export function getAllPermission(params) {
  return request({
    url: '/omnimedia-web-usercenter/permission/getAllPermission',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 菜单权限修改/添加

export var addPermission = function addPermission(data) {
  return request({
    url: '/omnimedia-web-usercenter/permission/addPermission',
    method: 'post',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}; //异步根据父id获取菜单权限列表

export function getPermissionAsync(params) {
  return request({
    url: '/omnimedia-web-usercenter/permission/getPermissionAsync',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //删除菜单权限

export var deletePermission = function deletePermission(data) {
  return request({
    url: '/omnimedia-web-usercenter/permission/deletePermission',
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'appMark': appMark + '.web',
      'content-type': 'application/x-www-form-urlencoded'
    }
  });
}; //根据菜单id来获取其对应的数据规则

export function getPermRuleListByPermId(params) {
  return request({
    url: '/omnimedia-web-usercenter/permission/getPermRuleListByPermId',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //数据规则修改/添加

export var editPermissionRule = function editPermissionRule(data) {
  return request({
    url: '/omnimedia-web-usercenter/permission/editPermissionRule',
    method: 'post',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}; // 根据id删除数据规则

export var deletePermissionRule = function deletePermissionRule(data) {
  return request({
    url: '/omnimedia-web-usercenter/permission/deletePermissionRule',
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'appMark': appMark + '.web'
    }
  });
}; //修改密码

export var changePasswords = function changePasswords(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/changePasswords',
    method: 'post',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
};