function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'adCenter-';
export default {
  path: '/adCenter',
  name: 'adCenter',
  redirect: {
    name: "".concat(pre, "materialManager/picture")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'materialManager/picture',
    name: "".concat(pre, "picture"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '图片'
    }),
    component: function component() {
      return import('@/pages/adCenter/materialManager/picture');
    }
  }, {
    path: 'materialManager/video',
    name: "".concat(pre, "video"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '视频'
    }),
    component: function component() {
      return import('@/pages/adCenter/materialManager/video');
    }
  }, {
    path: 'materialManager/video/edit',
    name: "".concat(pre, "videoEdit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '视频编辑'
    }),
    component: function component() {
      return import('@/pages/adCenter/materialManager/video/editModal');
    }
  }, {
    path: 'materialManager/music',
    name: "".concat(pre, "music"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '音乐'
    }),
    component: function component() {
      return import('@/pages/adCenter/materialManager/music');
    }
  }, {
    path: 'materialManager/audio',
    name: "".concat(pre, "audio"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '音频'
    }),
    component: function component() {
      return import('@/pages/adCenter/materialManager/audio');
    }
  }, {
    path: 'pictureSettings/watermark',
    name: "".concat(pre, "watermark"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '水印图片'
    }),
    component: function component() {
      return import('@/pages/adCenter/pictureSettings/watermark');
    }
  }, {
    path: 'pictureSettings/label',
    name: "".concat(pre, "label"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '图片标签'
    }),
    component: function component() {
      return import('@/pages/adCenter/pictureSettings/label');
    }
  }, {
    path: 'materialManager/file',
    name: "".concat(pre, "file"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '文件'
    }),
    component: function component() {
      return import('@/pages/adCenter/materialManager/file');
    }
  }]
};