function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import Vue from 'vue';
import VueRouter from 'vue-router';
import iView from 'view-design';
import util from '@/libs/util';
import Setting from '@/setting';
import store from '@/store/index'; // 路由数据

import routes from './routes';
import LocalStorage from 'lowdb/adapters/LocalStorage';
Vue.use(VueRouter); // 导出路由 在 main.js 里使用

var router = new VueRouter({
  routes: routes,
  mode: Setting.routerMode,
  base: Setting.routerBase
}); // 遍历菜单

function treeFind(tree, func) {
  var _iterator = _createForOfIteratorHelper(tree),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var data = _step.value;
      if (func(data)) return data;

      if (data.children) {
        var res = treeFind(data.children, func);
        if (res) return res;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return null;
}
/**
 * 路由拦截
 * 权限验证
 */


router.beforeEach(function (to, from, next) {
  if (to.meta.noAuthCheck === true) {
    next();
  } else {
    var menuData = JSON.parse(localStorage.getItem('setSider')) || [];
    var result = treeFind(menuData, function (node) {
      return node.path === to.path;
    }); //查找菜单对应的perms（按钮权限）

    console.log(result);

    if (result && result.perms !== '') {
      //把权限赋给当前路由的meta信息（hasPermission判断）
      if (result.perms === null || result.perms === 'null') {
        //转换吓perms数据类型 用于前端判断
        result.perms = '';
      }

      to.meta.perms = result.perms.split(',');
    } //特殊处理下文章发布，新增页面（单独的路由）
    // if(to.path === '/editArticle' || to.path === '/edit-institutionalArticles' ){
    // }


    if (Setting.showProgressBar) iView.LoadingBar.start(); // 判断是否需要登录才可以进入

    if (to.matched.some(function (_) {
      return _.meta.auth;
    })) {
      // 这里依据 token 判断是否登录，可视情况修改
      var token = util.cookies.get('token');

      if (token && token !== 'undefined') {
        next();
      } else {
        // 没有登录的时候跳转到登录界面
        // 携带上登陆成功之后需要跳转的页面完整路径
        next({
          name: 'login' // query: {
          //     redirect: to.fullPath
          // }

        });
        console.log(to.fullPath);
      }
    } else {
      // 不需要身份校验 直接通过
      next();
    }
  }
});
router.afterEach(function (to) {
  if (Setting.showProgressBar) iView.LoadingBar.finish(); // 多页控制 打开新的页面

  store.dispatch('admin/page/open', to); // 更改标题

  util.title({
    title: to.meta.title
  }); // 返回页面顶端

  window.scrollTo(0, 0);
});
export default router;