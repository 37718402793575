import request from '@/plugins/request';
import qs from 'qs'; //获取URL'?'后的参数

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

var appMark = ''; // if (localStorage.getItem("appmark") === 'null' || localStorage.getItem("appmark") === undefined || localStorage.getItem("appmark") === null) { //不存在就取
//     appMark = getUrlVars()["appmark"];
//     localStorage.setItem('appmark', appMark)
// } else {
//     appMark = localStorage.getItem('appmark')
// }
// appMark === undefined ? appMark = null : appMark
//先比较localStorage里的mark是否一样，
// if(getUrlVars()["appmark"] === localStorage.getItem('appmark')){
//     appMark = localStorage.getItem('appmark')
// }else{ 
//     //如果local有值就取
//     if(localStorage.getItem("appmark") != null || localStorage.getItem("appmark") != undefined ||localStorage.getItem("appmark") != 'null'){
//         appMark = localStorage.getItem('appmark')
//     }else{
//         appMark = getUrlVars()["appmark"];
//         localStorage.setItem('appmark', appMark)
//     }
// }

appMark = getUrlVars()["appmark"];

if (getUrlVars()["appmark"] === undefined) {
  appMark = localStorage.getItem('appmark');
}

localStorage.setItem('appmark', appMark);
console.log('--------------入口appMark:' + appMark + '------------------');
export function AccountLogin(params) {
  return request({
    url: '/omnimedia-web-usercenter/user/login',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    } // 添加请求头**

  });
}
export function AccountRegister(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data: data
  });
} //用户信息列表

export function GetUserList(params) {
  return request({
    url: '/omnimedia-web-usercenter/user/queryUserList',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //新增/编辑用户

export function AddUser(data) {
  return request({
    url: '/omnimedia-web-usercenter/user/addUser',
    method: 'post',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}