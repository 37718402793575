import cookies from './util.cookies';
import log from './util.log';
import db from './util.db';
import Setting from '@/setting';
var util = {
  cookies: cookies,
  log: log,
  db: db
}; //栏目树处理

util.mapTree = function (data) {
  data.forEach(function (item) {
    //遍历树 处理对应的title
    if (item.children == null || item.children == undefined) {
      delete item.children;
    }

    if (item.children) {
      util.mapTree(item.children);
    }
  }); // console.log(data)
}; //递归遍历树


util.treeIterator = function (tree, func) {
  tree.forEach(function (node) {
    func(node);
    node.children && util.treeIterator(node.children, func);
  });
}, //转换时间格式
util.renderTime = function (date) {
  var dates = new Date(date).toJSON();
  return new Date(+new Date(dates) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
};

util.getQueryString = function () {
  var url = location.search; //获取url中"?"符后的字串

  var theRequest = new Object();

  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    strs = str.split("&");

    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }

  return theRequest;
};

function tTitle() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}
/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */


util.title = function (_ref) {
  var title = _ref.title,
      count = _ref.count;
  title = tTitle(title);
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
}; //获取随机数


util.createNum = function (length) {
  var data = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  var nums = "";

  for (var i = 0; i < length; i++) {
    var r = parseInt(Math.random() * 61, 10);
    nums += data[r];
  }

  return nums;
}; // 防抖:多次触发事件后，事件处理函数只执行一次，并且是在触发操作结束时执行。


util.debounce = function (fn, delay) {
  var delay = delay || 200;
  var timer;
  return function () {
    var th = this;
    var args = arguments;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delay);
  };
}; // 节流:触发函数事件后，短时间间隔内无法连续调用，只有上一次函数执行后，过了规定的时间间隔，才能进行下一次的函数调用。


util.throttle = function (fn, interval) {
  var last;
  var timer;
  var interval = interval || 200;
  return function () {
    var th = this;
    var args = arguments;
    var now = +new Date();

    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(th, args);
      }, interval);
    } else {
      last = now;
      fn.apply(th, args);
    }
  };
}; //标准时间格式转换


util.changeTime = function (date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
};

util.fillDate = function () {
  //日期 
  var now = new Date(); //获取系统日期，即Sat Jul 29 08:24:48 UTC+0800 2006 

  var yy = now.getFullYear();
  ; //截取年，即2006 

  var mm = now.getMonth() + 1; //截取月，即07 

  var dd = now.getDate(); //截取日，即29 

  var cal = yy + "." + mm + "." + dd; //取时间 

  var hh = now.getHours(); //截取小时，即8 

  var mm = now.getMinutes(); //截取分钟，即34 

  var ss = now.getTime() % 60000; //获取时间，因为系统中时间是以毫秒计算的， 所以秒要通过余60000得到。 

  ss = (ss - ss % 1000) / 1000; //然后，将得到的毫秒数再处理成秒 

  var clock = hh + ':'; //将得到的各个部分连接成一个日期时间 

  if (mm < 10) clock += '0'; //字符串 

  clock += mm + ':';
  if (ss < 10) clock += '0';
  clock += ss; //向页面的相应位置进行赋值

  $(".date").html(cal + " " + clock);
  var timeID = setTimeout(fillDate, 1000);
}; //删除url指定参数


util.DelQueryString = function (url, paramKey) {
  var beforeUrl = url.substr(0, url.indexOf("?")); //?之前主地址

  var afterUrl = url.substr(url.indexOf("?") + 1); //？之后参数路径

  var nextUrl = "";
  var arr = new Array();

  if (afterUrl != "") {
    var urlParamArr = afterUrl.split("&"); //将参数按照&符分成数组

    for (var i = 0; i < urlParamArr.length; i++) {
      var paramArr = urlParamArr[i].split("="); //将参数键，值拆开
      //如果键雨要删除的不一致，则加入到参数中

      if (paramArr[0] !== paramKey) {
        arr.push(urlParamArr[i]);
      }
    }
  }

  if (arr.length > 0) {
    nextUrl = "?" + arr.join("&");
  }

  url = beforeUrl + nextUrl;
  return url;
};

util.formatDate = function (date) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd hh:mm:ss';

  if (typeof date === 'number') {
    date = new Date(date);
  }

  var o = {
    "M+": date.getMonth() + 1,
    //月份
    "d+": date.getDate(),
    //日
    "h+": date.getHours(),
    //小时
    "m+": date.getMinutes(),
    //分
    "s+": date.getSeconds(),
    //秒
    "q+": Math.floor((date.getMonth() + 3) / 3),
    //季度
    "S": date.getMilliseconds() //毫秒

  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  }

  return fmt;
};

function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }

  setTimeout(task, 16);
}
/**
     * @description: extractTree扁平化操作
     * @param {Array} arrs     必填
     * @param {String} childs  必填
     * @param {Array} attrArr  选填  说明:只对arrs的某一个或多个字段扁平化，例子 attrArr=['id','pid'] 
     * @return {Array} 
     */
//以下注释是extractTree(List, 'children') 执行时


function extractTree(arrs, childs, attrArr) {
  var attrList = []; //存储数组对象的key

  if (!Array.isArray(arrs) && !arrs.length) return []; //如果arrs不是数组对象则直接退出

  if (typeof childs !== 'string') return []; //定义第二参数必须为字符串

  if (!Array.isArray(attrArr) || Array.isArray(attrArr) && !attrArr.length) {
    attrList = Object.keys(arrs[0]); //存储数组对象的key,这里attrList=["id","pid","name","children"]

    if (attrList.indexOf(childs) !== -1) {
      //如果第二参数是children
      attrList.splice(attrList.indexOf(childs), 1); //那么就把attrList的children去掉，此时就变成attrList=["id","pid","name"]
    }
  } else {
    attrList = attrArr;
  }

  var list = [];

  var getObj = function getObj(arr) {
    //递归操作
    //arr = arrs 
    arr.forEach(function (row) {
      var obj = {}; //attrList=["id","pid","name"]

      attrList.forEach(function (item) {
        obj[item] = row[item] ? row[item] : "";
      });
      list.push(obj);

      if (row[childs]) {
        //如果有children这个字段，说明有下一级
        getObj(row[childs]); //递归
      }
    });
    return list;
  };

  return getObj(arrs);
}

export { requestAnimation, extractTree };
export default util;